import React, { useState, useEffect } from 'react';
import { Stack, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField, Button, IconButton, MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingPage from '../components/LoadingPage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Path = (string | number)[];

const setValueAtPath = (obj: any, path: Path, value: any): any => {
  if (path.length === 0) {
    return value;
  }
  const [key, ...rest] = path;
  if (Array.isArray(obj)) {
    const index = key as number;
    const updatedArray = [...obj];
    updatedArray[index] = setValueAtPath(obj[index], rest, value);
    return updatedArray;
  } else {
    return {
      ...obj,
      [key]: setValueAtPath(obj[key], rest, value),
    };
  }
};

const deleteItemAtPath = (obj: any, path: Path): any => {
  if (path.length === 0) return obj;
  const [key, ...rest] = path;
  if (rest.length === 0) {
    if (Array.isArray(obj)) {
      const index = key as number;
      const updatedArray = [...obj];
      updatedArray.splice(index, 1);
      return updatedArray;
    } else {
      const updatedObj = { ...obj };
      delete updatedObj[key as string];
      return updatedObj;
    }
  } else {
    if (Array.isArray(obj)) {
      const index = key as number;
      const updatedArray = [...obj];
      updatedArray[index] = deleteItemAtPath(obj[index], rest);
      return updatedArray;
    } else {
      return {
        ...obj,
        [key]: deleteItemAtPath(obj[key], rest),
      };
    }
  }
};

interface DynamicInputProps {
  data: any;
  path: Path;
  onChange: (path: Path, value: any) => void;
  onAddJob?: () => void; // Optional function for adding a job
}

const DynamicInput: React.FC<DynamicInputProps> = ({ data, path, onChange, onAddJob }) => {
  if (data === null || data === undefined) {
    return null; // Explicitly return null if data is null or undefined
  }

  if (typeof data === 'string' || typeof data === 'number') {
    const lastKey = path[path.length - 1];
    return (
      <Box sx={{ mt: 2 }}>
        <TextField
          label={String(lastKey)}
          fullWidth
          value={data}
          onChange={(e) => onChange(path, e.target.value)}
        />
      </Box>
    );
  } else if (Array.isArray(data)) {
    return (
      <Box sx={{ mt: 2 }}>
        {String(path) === 'experience,jobs' && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ mb: 2 }}
            onClick={onAddJob}
          >
            Add Job
          </Button>
        )}
        {data.map((item, index) => (
          <Box key={index} sx={{ mt: 2, border: '1px solid #aaa', borderRadius: '8px', padding: 2 }}>
            <DynamicInput data={item} path={[...path, index]} onChange={onChange} onAddJob={onAddJob} />
            <IconButton
              onClick={() => onChange(path, deleteItemAtPath(data, [index]))}
              sx={{ mt: 1 }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    );
  } else if (typeof data === 'object' && data !== null) {
    return (
      <Box sx={{ mt: 2 }}>
        {Object.keys(data).map((key) => (
          <Box key={key} sx={{ mt: 1 }}>
            <DynamicInput data={data[key]} path={[...path, key]} onChange={onChange} onAddJob={onAddJob} />
          </Box>
        ))}
      </Box>
    );
  } else {
    return null;
  }
};

const Profile: React.FC = () => {
  const [sections, setSections] = useState<{ [key: string]: any }>({});
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [sectionContent, setSectionContent] = useState<any>({});
  const [language, setLanguage] = useState<string>('br');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/common/${language}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setSections(jsonData.sections);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [language]);

  const handleExpandChange = (section: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedSection(isExpanded ? section : null);
    if (isExpanded) {
      setSectionContent(sections[section] || {});
    } else {
      setSectionContent({});
    }
  };

  const handleContentChange = (path: Path, value: any) => {
    setSectionContent((prevContent: any) => {
      const updatedContent = setValueAtPath(prevContent, path.slice(1), value);
      return updatedContent;
    });
  };

  const handleAddJob = () => {
    setSectionContent((prevContent: any) => {
      console.log(prevContent);
      // Check if the 'jobs' array exists in 'experience'. If not, initialize it as an empty array.
      const updatedJobs = [
        {
          company: '',
          role: '',
          start: '',
          end: '',
          description: '',
        },
        ...(prevContent.jobs || []), // Spread the existing jobs (or an empty array if none exist)
      ];

      // Return the updated content with the new job added to the start of the 'jobs' array
      return {
        ...prevContent,
        jobs: updatedJobs,
      };
    });
  };

  const handleSave = async () => {
    const updatedSections = {
      ...sections,
      [expandedSection!]: sectionContent,
    };

    // Clean up any potential null keys
    Object.keys(updatedSections).forEach(key => {
      if (key === 'null') {
        delete updatedSections[key];
      }
    });

    setSections(updatedSections);

    try {
      const response = await fetch(`/api/resume/save/sections/${language}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sections: updatedSections }),
      });

      if (!response.ok) {
        throw new Error('Failed to save sections');
      }

      console.log('Sections saved successfully');
    } catch (error) {
      console.error('Error saving sections:', error);
    }

    setExpandedSection(null); // Collapse the section after saving
  };

  if (loading) return <LoadingPage />;

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Adjusts spacing between items if needed
          mb: 2
        }}
      >
        <Box>
          <Typography variant="h4">
            Editar Currículo
          </Typography>

          <Typography sx={{ mr: 2 }}>
            Selecione a Seção que deseja editar
          </Typography>
        </Box>

        <Select
          value={language}
          onChange={(e) => { handleSave(); setLanguage(e.target.value); }}
          sx={{
            border: '1px solid #fff',
            borderRadius: '4px', // Optional: rounding corners
            padding: '8px', // Optional: adjust padding
          }}
        >
          <MenuItem value="br">Português Brasileiro</MenuItem>
          <MenuItem value="en">English</MenuItem>
        </Select>
      </Box>
      <Stack spacing={2}>
        {Object.keys(sections).map((sectionKey) => (
          <Accordion
            key={sectionKey}
            expanded={expandedSection === sectionKey}
            onChange={handleExpandChange(sectionKey)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h6">
                {sections[sectionKey].title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DynamicInput
                data={sectionContent}
                path={[sectionKey]}
                onChange={handleContentChange}
                onAddJob={handleAddJob}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSave}
              >
                Salvar
              </Button>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </div>
  );
};

export default Profile;
