import React from 'react'
import { CircularProgress } from '@mui/material'

type Props = {}

const LoadingPage: React.FC = (props: Props) => {
  return (
    <div><CircularProgress /></div>
  )
}

export default LoadingPage