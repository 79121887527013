import React, { useState } from 'react';

const Settings: React.FC = () => {
    const [title, setTitle] = useState(document.title);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const updateTitle = () => {
        document.title = title;
    };

    return (
        <div>
            <h1>Settings</h1>
            <div>
                <label>Website Title: </label>
                <input 
                    type="text" 
                    value={title} 
                    onChange={handleTitleChange} 
                />
                <button onClick={updateTitle}>Update Title</button>
            </div>
        </div>
    );
};

export default Settings;
