import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Grid, Paper, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import ProxyIcon from '@mui/icons-material/Http'; // Example icon for Proxy
import CodeIcon from '@mui/icons-material/Code';

interface Container {
  id: number;
  name: string;
  status: string;
}

const Home: React.FC = () => {
  const [containers, setContainers] = useState<Container[]>([]);
  const [logs, setLogs] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [currentContainerId, setCurrentContainerId] = useState<number | null>(null);
  const [alert, setAlert] = useState<{ message: string; severity: 'success' | 'error' } | null>(null);

  useEffect(() => {
    const fetchContainers = async () => {
      try {
        const response = await axios.get('/api/containers');
        setContainers(response.data);
      } catch (error) {
        console.error('Error fetching containers', error);
      }
    };

    fetchContainers();
  }, []);

  const handleRestart = async (id: number) => {
    try {
      await axios.post(`/api/containers/${id}/restart`);
      setAlert({ message: `Container ${id} restarted`, severity: 'success' });
    } catch (error) {
      console.error(`Error restarting container ${id}`, error);
      setAlert({ message: `Error restarting container ${id}`, severity: 'error' });
    }
  };

  const handleStop = async (id: number) => {
    try {
      await axios.post(`/api/containers/${id}/stop`);
      setAlert({ message: `Container ${id} stopped`, severity: 'success' });
    } catch (error) {
      console.error(`Error stopping container ${id}`, error);
      setAlert({ message: `Error stopping container ${id}`, severity: 'error' });
    }
  };

  const handleViewLogs = async (id: number) => {
    setCurrentContainerId(id);
    setOpenDialog(true);
    setLoadingLogs(true);
    try {
      const response = await axios.get(`/api/containers/${id}/logs`);
      setLogs(response.data.logs);
    } catch (error) {
      console.error(`Error fetching logs for container ${id}`, error);
    } finally {
      setLoadingLogs(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLogs(null);
  };

  const handleCloseAlert = () => {
    setAlert(null);
  };

  return (
    <div>
      {/* Alert for showing status updates */}
      {alert && (
        <Box mb={2}>
          <Alert
            severity={alert.severity}
            onClose={handleCloseAlert}
          >
            {alert.message}
          </Alert>
        </Box>
      )}

      <Box display="flex" justifyContent="flex-start" mt={2} gap={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ProxyIcon />}
          component="a"
          href="http://proxy.murillomuller.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          Proxy CASSI
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<CodeIcon />}
          component="a"
          href="https://code.murillomuller.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          Code Server
        </Button>
      </Box>
      <Box mt={4}>
        <Typography variant="h5">My Containers</Typography>
        <Grid container spacing={2} mt={2}>
          {containers.map(container => (
            <Grid item xs={12} sm={6} md={4} key={container.id}>
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h6">{container.name}</Typography>
                <Typography>Status: {container.status}</Typography>
                <Button variant="contained" color="primary" onClick={() => handleRestart(container.id)} style={{ marginRight: '10px' }}>
                  Restart
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleStop(container.id)} style={{ marginRight: '10px' }}>
                  Stop
                </Button>
                <Button variant="outlined" onClick={() => handleViewLogs(container.id)}>
                  View Logs
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Dialog for displaying logs */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Container Logs</DialogTitle>
        <DialogContent dividers>
          {loadingLogs ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                height: '400px',
                overflowY: 'scroll',
                whiteSpace: 'pre-wrap',
                fontFamily: 'monospace',
                fontSize: '0.9rem',
                border: '1px solid #ddd',
                borderRadius: '4px',
                p: 2,
              }}
            >
              <pre>{logs}</pre>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
