// src/App.tsx

import React, { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import keycloak from './keycloak/keycloak';

const initKeycloak = (() => {
  let initPromise: Promise<boolean> | null = null;

  return () => {
    if (!initPromise) {
      initPromise = keycloak.init({ onLoad: 'login-required' });
    }
    return initPromise;
  };
})();

const App: React.FC = () => {
  const [keycloakReady, setKeycloakReady] = useState(false);

  useEffect(() => {
    initKeycloak().then(authenticated => {
      setKeycloakReady(true);
      if (!authenticated) {
        console.warn('User is not authenticated, redirecting to login.');
        keycloak.login();
      }
    }).catch(error => {
      console.error('Keycloak initialization failed', error);
    });
  }, []);

  if (!keycloakReady) return <div>Loading...</div>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dashboard />
    </ThemeProvider>
  );
};

export default App;
