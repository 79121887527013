import { createTheme } from '@mui/material/styles';

// Define your colors and typography based on your SCSS variables
const primaryColor = '#da0037';
const backgroundColor = '#222';
const backgroundDarkColor = '#171717';
const backgroundDarkerColor = '#131313';
const grayColor = '#747474';
const whiteColor = '#fff';
const textColor = '#ededed';
const textDarkColor = '#131313';
const textSecondaryColor = '#afafaf';

// Create a Material-UI theme using these variables
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    background: {
      default: backgroundDarkColor,
      paper: backgroundDarkerColor,
    },
    text: {
      primary: textColor,
      secondary: textSecondaryColor,
      disabled: grayColor,
    },
    divider: backgroundColor,
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    body1: {
      fontSize: '15px',
      lineHeight: 1.75,
      fontWeight: 400,
      color: textColor,
    },
    h1: {
      fontSize: '22px',
      fontWeight: 700,
      '&::after': {
        content: '""',
        background: primaryColor,
        borderRadius: '25px',
        display: 'block',
        height: '5px',
        marginTop: '9px',
        width: '30px',
      },
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
          root: {
            color: whiteColor
          },
        },
  },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.6em 1em',
          border: `2px solid ${primaryColor}`,
          borderRadius: '5px',
          fontSize: '14px',
          color: whiteColor,
          transition: 'all 0.5s ease-in-out',
          '&:hover': {
            backgroundColor: primaryColor,
          },
          '&.secondary': {
            border: `2px solid ${whiteColor}`,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: whiteColor,
              color: textDarkColor,
              border: `2px solid ${whiteColor}`,
            },
            
          },
          '&.MuiButton-outlinedPrimary': {
  color: primaryColor, // Set text color to primary
  border: `2px solid ${primaryColor}`, // Set border color to primary
  '&:hover': {
    backgroundColor: primaryColor, // Change background color to primary on hover
    color: whiteColor, // Change text color to white on hover
  },
  '& .MuiButton-startIcon .MuiSvgIcon-root': {
    color: 'inherit', // Set startIcon color to primary
  },
},
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColor,
          borderBottom: `4px solid ${primaryColor}`,
          borderRight: `4px solid ${primaryColor}`,
          borderLeft: `1px solid ${primaryColor}`,
          borderRadius: '300px',
          maxHeight: '170px',
          maxWidth: '170px',
          width: 'auto',
          height: 'auto',
          alignSelf: 'center'
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: {
          lineHeight: 'normal',
          margin: '30px 0 0 0',
          fontWeight: 700,
          fontSize: '24px',
          color: whiteColor,
        },
        subtitle1: {
          color: primaryColor,
          fontSize: '12px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#007bff',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          outline: 0,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            color: primaryColor,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor,
          borderRadius: '25px',
          height: '1px',
          margin: '45px auto',
          width: '100%',
        },
      },
    },

  },
});

export default theme;
