// src/components/Dashboard.tsx

import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box, Avatar, Button } from '@mui/material';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SettingsIcon from '@mui/icons-material/Settings';
import Home from '../pages/Home';
import Settings from '../pages/Settings';
import Resume from '../pages/Resume';
import { RecentActors } from '@mui/icons-material';
import keycloak from '../keycloak/keycloak'; // Import Keycloak instance

const drawerWidth = 240;

const Dashboard: React.FC = () => {
  useEffect(() => {
    document.title = 'Dashboard - Murillo Müller'; // Set title when this component is mounted
  }, []);

  const handleLogout = () => {
    keycloak.logout(); // Call Keycloak logout method
  };

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Dashboard
            </Typography>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Avatar src="https://www.murillomuller.dev/images/new_profile_pic.jpg" style={{ marginTop: 10 }} />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              <ListItem button component={Link} to="/">
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/resume">
                <ListItemIcon>
                  <RecentActors />
                </ListItemIcon>
                <ListItemText primary="Currículo" />
              </ListItem>
              <ListItem button component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default Dashboard;
